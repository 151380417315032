import { useCustomTokens } from "./useCustomTokens";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Token } from "sushi/currency";
import { getAddress } from "viem";
import { DEFAULT_TOKEN_LIST } from "../constants/tokens/default-token-list";

interface UseTokensParams {
	chainId: number;
}

type Data = {
	id: string;
	address: string;
	name: string;
	symbol: string;
	decimals: number;
	logoURI?: string;
};

export const fetchTokensQueryFn = async ({ customTokenMap }: { customTokenMap: Record<string, Token> }) => {
	const data: Data[] = [...DEFAULT_TOKEN_LIST];

	Object.entries(customTokenMap).forEach(([id, { address, name, symbol, decimals }]) => {
		data.push({
			id,
			address: address!,
			name: name!,
			symbol: symbol!,
			decimals,
		});
	});

	return data.reduce<Record<number, Record<string, Token>>>((acc, { id, name, symbol, decimals }) => {
		const [_chainId, _address] = id.split(":");

		const chainId = Number(_chainId);
		const address = String(_address);

		if (!acc?.[chainId]) acc[chainId] = {};

		const map = acc[chainId] as Record<string, Token>;

		map[getAddress(address)] = new Token({
			chainId,
			name,
			decimals,
			symbol,
			address,
		});

		return acc;
	}, {});
};

export const useTokens = ({ chainId }: UseTokensParams) => {
	const { data: customTokenMap } = useCustomTokens();
	return useQuery({
		queryKey: ["tokens", Object.keys(customTokenMap).length],
		queryFn: () => fetchTokensQueryFn({ customTokenMap }),
		select: (data) => data[chainId],
		placeholderData: keepPreviousData,
		staleTime: 900000, // 15 mins
		// gcTime: ms('24h'), // 24hs
	});
};
