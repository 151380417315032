import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { ChainId } from "sushi/chain";
import { Amount, Native, Token, Type } from "sushi/currency";
import { Address, erc20Abi, isAddress, zeroAddress } from "viem";

import { Config, serialize, useBalance, useConfig, usePublicClient, UsePublicClientReturnType } from "wagmi";
import { GetBalanceReturnType, readContracts } from "wagmi/actions";
import { ChainIdTypes } from "@/constants/chain-id";
import { getBalance } from "viem/actions";

interface QueryBalanceParams {
	chainId: ChainId | ChainIdTypes | undefined;
	currencies: (Type | undefined)[];
	account: Address | undefined;
	nativeBalance?: bigint;
	config: Config;
	client: UsePublicClientReturnType;
}

export const queryFnUseBalances = async ({
	chainId,
	currencies,
	account,
	nativeBalance,
	config,
	client,
}: QueryBalanceParams) => {
	if (!account || !chainId || !currencies) return null;

	let native = nativeBalance;
	if (typeof native === "undefined") {
		native =
			(await client?.getBalance({
				address: account,
			})) ?? 0n;
	}

	const [validatedTokens, validatedTokenAddresses] = currencies.reduce<[Token[], Address[]]>(
		(acc, currencies) => {
			if (chainId && currencies && isAddress(currencies.wrapped.address)) {
				acc[0].push(currencies.wrapped);
				acc[1].push(currencies.wrapped.address as Address);
			}

			return acc;
		},
		[[], []]
	);

	const data = await readContracts(config, {
		contracts: validatedTokenAddresses.map(
			(token) =>
				({
					chainId,
					address: token,
					abi: erc20Abi,
					functionName: "balanceOf",
					args: [account],
				} as const)
		),
	});

	const _data = data.reduce<Record<string, Amount<Type>>>((acc, _cur, i) => {
		const amount = data[i].result;
		if (typeof amount === "bigint") {
			acc[validatedTokens[i].address] = Amount.fromRawAmount(validatedTokens[i], amount);
		}
		return acc;
	}, {});

	_data[zeroAddress] = Amount.fromRawAmount(Native.onChain(chainId), native);

	return _data;
};

interface UseBalanceParams {
	chainId: ChainId | ChainIdTypes | undefined;
	currencies: (Type | undefined)[];
	account: Address | undefined;
	enabled?: boolean;
}

export const useBalancesWeb3 = ({ chainId, currencies, account, enabled = true }: UseBalanceParams) => {
	const { data: nativeBalance, queryKey } = useBalance({
		chainId,
		address: account,
		query: { enabled },
	});

	const config = useConfig();
	const client = usePublicClient({
		config,
		chainId,
	});

	// useWatchByInterval({ key: queryKey, interval: 7000 });

	useEffect(() => {
		if (currencies && currencies.length > 100) {
			throw new Error(
				"useBalancesWeb3: currencies length > 100, this will hurt performance and cause rate limits"
			);
		}
	}, [currencies]);

	return useQuery({
		queryKey: ["useBalancesWeb3", { chainId, currencies, account, nativeBalance: serialize(nativeBalance) }],
		queryFn: () =>
			queryFnUseBalances({
				chainId,
				currencies,
				account,
				nativeBalance: nativeBalance?.value,
				config,
				client,
			}),
		refetchInterval: 7000,
		enabled: Boolean(chainId && account && enabled && currencies),
	});
};
