import { graphql } from "../../../graphql/v2-ethereum/index";

export const GetTokenQuery = graphql(`
	query GetToken($tokenAddress: String!) {
		tokenDayDatas(first: 1, orderBy: date, orderDirection: desc, where: { token: $tokenAddress }) {
			priceUSD
			totalLiquidityUSD
			totalLiquidityETH
			token {
				id
				name
				symbol
				decimals
			}
		}
	}
`);
