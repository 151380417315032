import { CHAIN_ID } from "@/constants/chain-id";
import { DEFAULT_TOKEN_LIST } from "@/constants/tokens/default-token-list";
import { execute } from "@/subgraph/graphql/execute";
import { GetTokenQuery } from "@/subgraph/queries/v2-ethereum/token/get-token";
import { Token } from "sushi/currency";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Address, erc20Abi, getAddress, isAddress } from "viem";
import { useReadContracts } from "wagmi";
import { API_BASE_URL } from "@/constants/api-base-url";

export const useTokenInfo = ({ tokenAddress }: { tokenAddress?: Address }) => {
	const [shouldCallContracts, setShouldCallContracts] = useState(false);
	const queryClient = useQueryClient(); // Access to react-query's query client
	const _contract = {
		address: tokenAddress,
		abi: erc20Abi,
	};
	const { data: contractData } = useReadContracts({
		contracts: [
			{
				..._contract,
				functionName: "name",
			},
			{
				..._contract,
				functionName: "symbol",
			},
			{
				..._contract,
				functionName: "decimals",
			},
		],
		query: {
			enabled: !!tokenAddress && isAddress(tokenAddress) && shouldCallContracts,
			retry: false,
		},
	});

	useEffect(() => {
		if (shouldCallContracts) {
			queryClient.invalidateQueries({ queryKey: ["useTokenInfo", { tokenAddress }] }); // Refetch the query
			setShouldCallContracts(false);
		}
	}, [shouldCallContracts, queryClient, tokenAddress]);

	return useQuery({
		queryKey: ["useTokenInfo", { tokenAddress, contractData }],
		// staleTime: Infinity,
		queryFn: async () => {
			if (!tokenAddress || !isAddress(tokenAddress)) return undefined;

			const tokenInList = DEFAULT_TOKEN_LIST?.find((i) => i.address?.toLowerCase() === tokenAddress);

			const data = await execute("v2", GetTokenQuery, { tokenAddress });
			const tokenData = data?.data?.tokenDayDatas?.[0];

			if (!tokenData && !tokenInList && !shouldCallContracts && !contractData) {
				setShouldCallContracts(true);
			}

			const priceRes = await fetch(`${API_BASE_URL}/price/v1/${CHAIN_ID}`);
			const priceData = (await priceRes.json()) as Record<Address, number>;
			const tokenPrice = priceData?.[getAddress(tokenAddress)] as undefined | number;

			const token = new Token({
				chainId: CHAIN_ID,
				name: tokenInList?.name ?? tokenData?.token?.name ?? contractData?.[0].result ?? "N/A",
				symbol: tokenInList?.symbol ?? tokenData?.token?.symbol ?? contractData?.[1].result ?? "N/A",
				decimals: tokenInList?.decimals ?? tokenData?.token?.decimals ?? contractData?.[2].result ?? 18,
				address: tokenAddress,
			});

			return {
				token: token,
				logoURI: tokenInList?.logoURI ?? undefined,
				priceUSD: tokenPrice?.toString() ?? tokenData?.priceUSD,
				totalLiquidityUSD: tokenData?.totalLiquidityUSD,
				totalLiquidityETH: tokenData?.totalLiquidityETH,
			};
		},
		enabled: !!tokenAddress,
	});
};
